import { apply, isSupported } from '@oddbird/popover-polyfill/fn'
import { createRoot } from 'react-dom/client'
import { initInstrumentation } from './core-web/instrumentation'

// https://mswjs.io/docs/recipes/deferred-mounting
async function enableMocking() {
  if (import.meta.env.MODE !== 'test') {
    return null
  }

  const { setupMocks } = await import('./mocks/setup')

  return setupMocks()
}

// Polyfill Popover (Safari <17) https://caniuse.com/?search=popover
if (!isSupported()) {
  apply()
}

/**
 * We sometimes see errors where a module fails to load.
 * From what we can observe in Datadog, these are always occurring
 * in the background during a preload, and are not visible to the user.
 *
 * We've verified that during a deployment, the new assets are always
 * present in the bucket before updating our index.html and version.json.
 *
 * We also never remove the old assets from the bucket, so they should
 * always be available.
 *
 * As such, this is unlikely to have a user-visible impact, but it's
 * filling up our logs with noise and triggering alerts.
 *
 * See https://github.co/vitejs/vite/pull/12084
 *
 * A more comprehensive fix can be found here:
 * https://github.com/vital-software/katoa/pull/18217,
 *
 * At the time of writing (2024-06-06) it requires modifying Vite internals,
 * so we're using this workaround to suppress the errors for now
 */
window.addEventListener('vite:preloadError', (event) => {
  if (!event || !('payload' in event)) {
    return
  }

  const payload = (event as unknown as Error & { payload?: Error }).payload

  if (
    payload?.message &&
    (payload.message.includes('Failed to fetch dynamically imported module') ||
      payload.message.includes('Importing a module script failed'))
  ) {
    console.warn('dynamic import failed', payload)
    // Prevent Vite from throwing the error
    // TODO: see if this is causing errors in production
    // event.preventDefault()
  }
})

// Render React Application
enableMocking()
  .then(async () => {
    const rootElement = document.getElementById('root')
    const { Router } = await import('./router')

    if (rootElement) {
      createRoot(rootElement).render(<Router />)
    } else {
      throw new Error('Cannot find a root element to mount our React App into')
    }
  })
  .catch((error) => {
    console.error('Error starting Patient App', error)
  })

// Configure Instrumentation
initInstrumentation().catch((error) => {
  console.error('Error starting instrumentation', error)
})
