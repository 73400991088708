import { facility } from './hydrated-state'

const { VITE_ENVIRONMENT, VITE_VERSION } = import.meta.env

const sharedDatadogConfig = {
  clientToken: 'pub0bcc53e0713b5d639caa62bb255dbf30',
  site: 'datadoghq.com',
  service: 'patient',
  env: VITE_ENVIRONMENT,
  version: VITE_VERSION,
  sessionSampleRate: 100,
  storeContextsAcrossPages: true,
}

export const initInstrumentation = async () => {
  if (VITE_ENVIRONMENT === 'production') {
    const { datadogRum } = await import('@datadog/browser-rum')

    datadogRum.init({
      ...sharedDatadogConfig,
      applicationId: 'c870695b-aa81-4b3a-954e-ae4f244a2a64',
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackLongTasks: true,
      trackResources: true,
      workerUrl: new URL('@datadog/browser-worker', import.meta.url).href,
      defaultPrivacyLevel: 'mask-user-input',
      compressIntakeRequests: true,
    })

    // Set Facility ID
    datadogRum.setGlobalContextProperty('facilityId', facility.id)
  }

  const { datadogLogs } = await import('@datadog/browser-logs')

  datadogLogs.init({
    ...sharedDatadogConfig,
    forwardErrorsToLogs: true,
    beforeSend: (log) => {
      // Filter out "TypeError: Load failed" logs, where the status code is 0
      // This is caused when a user navigates and the browser cancels the fetch request
      if (log.http?.status_code === 0) {
        return false
      }

      // Filter out logs caused by dynamic import/fetch/Apollo when we roll out a new version of the app,
      // which forces a refresh of the page
      if (log.view.url.includes('?version_reload=true')) {
        if (
          log.message.includes('Importing a module script failed.') ||
          log.message.includes('Load failed') ||
          log.message.includes('Failed to fetch') ||
          log.message.includes(
            'NetworkError when attempting to fetch resource.',
          )
        ) {
          return false
        }
      }

      // Filter out "Script error" logs, which are caused by cross-origin errors, likely for browser extensions
      if (log.message.includes('Uncaught "Script error."')) {
        return false
      }

      if (
        ['Fetch error POST', 'XHR error POST'].some((ignoredLog) =>
          log.message.includes(ignoredLog),
        )
      ) {
        return false
      }

      if (VITE_ENVIRONMENT !== 'production') {
        console.warn('[FAKE LOG TO DATADOG]:', log)

        return false
      }

      return true
    },
  })

  // Set Facility ID
  datadogLogs.setUserProperty('facilityId', facility.id)
}
